<template>
  <div class="home">
    <div class="flex flex-col items-center justify-center p-8 guide">
        <h2 class="mt-5">Yardım</h2>
        <p>Adam Asmaca ücretli ya da ücretsiz oynayabileceğin, harflere tıklayarak kelimeyi bulmaya çalıştığın bir kelime bulmaca oyunudur. Oyunda "Antrenman", "Kolay" ve "Zor" olacak şekilde 3 farklı oyun tipi bulunmaktadır.</p>
        <p>Kolay oyuna giriş bileti 69,99 TL, zor oyuna giriş bileti 79,99 TL'dir. Antrenman modu ücretsizdir.</p>
        <p>5 adet Joker (Harf Açma) içeren Joker Paketi'ni satın alma ücreti 19,99 TL'dir.</p>
        
        <h3 class="mt-10">Nasıl Oynanır?</h3>
        <ul class="list-disc ml-5 mt-2 mb-10">
            <li>
                Hedefin en az harfe basarak gizlenmiş kelimeyi doğru bir biçimde bulmaktır.
            </li>
            <li>
                Oyun rastgele bir kelime ile açılır. Sayfanın ortasında bu kelime ile ilgili ipucunu görebilirsin. Saklı kelime bir "futbol takımı",  bir "eşya", "coğrafya" ile alakalı bir terim ya da başka bir şey ile ilgili olabilir. 
            </li>
            <li>
                Kelimeyi açmak için 7 hata hakkın bulunmakta. İlk harf açılışında bir hak düşer. Bundan sonra her hatalı harf seçiminde bir hak düşer. 
            </li>
            <li>
                Verilen maksimum tahmin hakkında doğru tahminde bulunamazsan oyun sonlanır.
            </li>
            <li>
               Harflerden herhangi birine tıkladığında eğer harfin üzeri çiziliyorsa bu harf kelimede mevcut değil demektir. Doğru bir harfe tıkladığında bu harf kelimenin içinde görünür olur. Bu şekilde kelimeyi doğru bilene kadar harfleri açmaya devam edebilirsin. 
            </li>
        </ul>

        <h3>Oyun İçerisindeki İkonlar Ve Anlamları</h3>
        <div class="flex items-center justify-start w-full mb-4">
            <div class="icon-circle text-center mr-4 w-4" style="width: 64px">
                <img src="@/assets/life-icon.png" alt="" />
            </div>
            <p><b>Can:</b> Kelimeyi doğru tahmin etmek için kalan hamle sayısı</p>
        </div>
        <div class="flex items-center justify-start w-full mb-4">
            <div class="btn btn--xs btn--primary self-center mr-5 ml-1" style="width: 64px">
                <img src="@/assets/open-icon.png" alt="" />
            </div>
            <p><b>Joker (Harf Açma):</b> Kelime içerisinden bir harf açar.</p>
        </div>

        <h3 class="mt-10">Kolay Oyun</h3>
        <ul class="list-disc ml-5 mt-2 mb-10">
            <li>
                Kelimeyi doğru bilmek için 7 hata hakkın var.
            </li>
            <li>
                Kelimeyi eğer en fazla 2 hata ile bilirsen günlük 5GB,
            </li>
            <li>
                Eğer 3,4 ya da 5 hata yaparak bilirsen günlük 2.5 GB,
            </li>
            <li>
                6 hata ile bilirsen günlük 1 GB senin! 
            </li>
        </ul>
        <p>Kelimeyi bulamadığında veya 24 saat içerisinde oyunu tamamlayamadığında üzülme, günlük 500 MB teselli ödülü hesabına tanımlanacak!</p>
        <p>*İnternet paketleri Turkcell abonesi olman durumunda satın alınabilir ve yalnızca Türkiye'deki kullanımlarda geçerlidir.</p>
        <p>*Ödeme tipi değişikliği, hat devir işlemi gibi sistem değişikliği veya güncellemeleri sırasında GB gönderimi yapılamamaktadır. GB gönderimleri işlem tamamlandıktan sonra gerçekleştirilir.</p>

        <h3 class="mt-10">Zor Oyun</h3>
        <ul class="list-disc ml-5 mt-2 mb-10">
            <li>
                Kelimeyi doğru bilmek için 7 hata hakkın var.
            </li>
            <li>
                Kelimeyi eğer en fazla 1 hata ile bilirsen günlük 10 GB,
            </li>
            <li>
                Eğer 2, 3, 4 ya da 5 hata yaparak bilirsen günlük 5 GB,
            </li>
            <li>
                6 hata ile bilirsen günlük 2.5 GB,
            </li>
        </ul>
        <p>Kelimeyi bulamadığında veya 24 saat içerisinde oyunu tamamlayamadığında üzülme, günlük 1 GB teselli ödülü hesabına tanımlanacak!</p>
        <p>*İnternet paketleri Turkcell abonesi olman durumunda satın alınabilir ve yalnızca Türkiye'deki kullanımlarda geçerlidir.</p>
        <p>*Ödeme tipi değişikliği, hat devir işlemi gibi sistem değişikliği veya güncellemeleri sırasında GB gönderimi yapılamamaktadır. GB gönderimleri işlem tamamlandıktan sonra gerçekleştirilir.</p>

        <h3 class="mt-10">Antrenman</h3>
        <p>Kelimeyi bulmak için 7 hata hakkın var. Oyun sonunda " Tekrar Oyna" ya tıklayarak tekrar oyun oynayabilirsin.</p>
        <p>Antrenman modunda her 5 oyun oynayana 5 joker (harf açma) ücretsiz hediye edilir.</p>

        <h3 class="mt-10">Joker</h3>
        <ul class="list-disc ml-5 mt-2 mb-10">
            <li>
                Adam Asmaca'ya yeni kayıt olduysan 5 joker (harf açma) bizden sana hediye!
            </li>
            <li>
                Jokerleri oyun sonlarında ya da "Ana Menü"den satın alabilirsin. 
            </li>
            <li>
                Jokerleri bir sonraki oyunda kullanabilirsin. 
            </li>
            <li>
                Antrenman modunda her 5 oyun oynayana 5 joker (harf açma) ücretsiz hediye edilir.
            </li>
            <li>
                Jokerleri bedava ya da ödüllü oynanan tüm oyunlarda kullanabilirsin.
            </li>
            <li>
                Eğer Turkcell abonesi isen 19,99 TL karşılığında 5 Joker (Harf Açma) içeren Joker Paketi'ni satın alabilirsin. 
            </li>
        </ul>

        <hr>

        <router-link :to="'/terms-of-use?playerId=' + this.$route.query.playerId">Kullanım Koşulları</router-link>
        <router-link :to="'/privacy?playerId=' + this.$route.query.playerId">Gizlilik Sözleşmesi</router-link>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      content: "",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.table {
  border: 1px solid #C4C4C4;
  text-align: left;

  tr {
    td, th {
      border-bottom: 1px solid #C4C4C4;
      border-right: 1px solid #C4C4C4;
      padding: 5px 15px;
    }  
  }
}
</style>
